<template>
  <HomePageContent
    :show-action-buttons="!inAppView"
  />
</template>

<script>
import { useUserStore } from '@@/stores/User';
import AppViewMixin from '@@/utils/AppViewMixin';

export default {
  name: 'HomePage',

  mixins: [AppViewMixin],

  async setup() {
    const { query } = useRoute();
    const userStore = useUserStore();

    // NOTE: The query is accessed directly here instead of using AppViewMixin because asyncData()
    // doesn't have access to component instance.
    const inAppView = query.in_app_view === 'true';
    const isLoggedIn = !userStore.isGuest;

    // Redirect to /user/favorites if logged in and not rendered in mobile app
    if (isLoggedIn && !inAppView) {
      navigateTo({
        path: '/user/favorites/locations',
        query,
      });
    }
  },
};
</script>
